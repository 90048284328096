import { getCreditObjects } from 'api';
import {
  CACHE_TIME,
  STALE_TIME,
  errorHandler,
  generateCreditObjectsQueryKey,
} from 'helpers';

export const creditObjectsQuery = (dealId: string) => ({
  queryKey: generateCreditObjectsQueryKey(dealId),
  queryFn: () => getCreditObjects(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
