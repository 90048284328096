import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { updateDocumentProperties, uploadFile } from 'api';
import { DownloadButton, UploadButton } from 'components/Buttons';
import { DataAssociationsDropdownMenu } from 'components/DropdownMenus/DataAssociationsDropdownMenu';
import { EditDocumentModal } from 'components/Modals';
import { TableBoldedTypography } from 'components/Typography';
import {
  getDocumentObjectMutation,
  getDocumentObjectMutationForUploadProperties,
  documentObjectsQuery,
  invalidReasonsQuery,
} from 'dataLayer';
import { format, parseISO } from 'date-fns';
import { allowedContentTypes, successHandler } from 'helpers';
import { getDocumentTableStyles, years } from 'pages/Deals/Deal';
import { DOCUMENT_STATUSES } from 'pages/Deals/Deal/DealDocuments';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { DocumentObject } from 'types';
import { DocumentObjectProperties } from 'types/dealTypes';
import { RotateDropdownMenu } from '../DropdownMenus/RotateDropdownMenu';

export const filterAssociatedDocumentObjects = (
  hubspotDocumentObjects: DocumentObject[] | undefined,
  fileName?: string,
) =>
  hubspotDocumentObjects?.filter(
    (document) =>
      years.includes(document.properties.year) &&
      !!fileName &&
      document.properties.s3FileName === fileName,
  );

interface IProps {
  documentObject: DocumentObject;
  dealId: string;
  isCustomObject?: boolean;
  // fileName: string;
}

export const CSCDocumentTableCells: FunctionComponent<IProps> = ({
  documentObject,
  dealId,
  isCustomObject,
  // fileName,
}) => {
  const {
    dataAssociationColumnStyle,
    dataStatusColumnStyle,
    dataStatusColumnMenuButtonStyle,
    documentNameColumnStyle,
    invalidReasonsColumnStyle,
    invalidReasonsColumnMenuButtonStyle,
    noteColumnStyle,
    rotateFileColumnStyle,
    statusColumnStyle,
    uploadDateColumnStyle,
  } = getDocumentTableStyles('customObjectStyle');

  const statusOptions = [
    {
      displayName: '1 - Upload required',
      hubspotName: DOCUMENT_STATUSES.NOT_YET_RECIEVED,
    },
    {
      displayName: '2 - Pending review',
      hubspotName: DOCUMENT_STATUSES.PENDING_REVIEW,
    },
    {
      displayName: '3 - IQ CSC confirmed',
      hubspotName: DOCUMENT_STATUSES.IQ_CONFIRMED,
    },
    {
      displayName: '4 - IR CSC confirmed',
      hubspotName: DOCUMENT_STATUSES.IR_CONFIRMED,
    },
    {
      displayName: '5 - IQ QC confirmed',
      hubspotName: DOCUMENT_STATUSES.IQ_QC_CONFIRMED,
    },
    {
      displayName: '6 - IR QC confirmed',
      hubspotName: DOCUMENT_STATUSES.IR_QC_CONFIRMED,
    },
  ];

  const getStatusColor = (status: string) => {
    switch (status) {
      case DOCUMENT_STATUSES.NOT_YET_RECIEVED:
        return 'red';
      case DOCUMENT_STATUSES.PENDING_REVIEW:
        return 'orange';
      case DOCUMENT_STATUSES.IQ_CONFIRMED:
      case DOCUMENT_STATUSES.IR_CONFIRMED:
      case DOCUMENT_STATUSES.IQ_QC_CONFIRMED:
      case DOCUMENT_STATUSES.IR_QC_CONFIRMED:
        return 'green';
      default:
        return 'red';
    }
  };

  const { data: documentObjects, isLoading: isLoadingDocumentObjects } =
    useQuery(documentObjectsQuery(dealId));

  const customObjectAssocations = useMemo(
    () =>
      isCustomObject
        ? filterAssociatedDocumentObjects(
            documentObjects,
            documentObject.properties.s3FileName,
          )
        : undefined,
    [documentObjects, isCustomObject, documentObject.properties.s3FileName],
  );

  const { data: invalidReasons, isLoading: isLoadingInvalidReasonOptions } =
    useQuery(invalidReasonsQuery());

  const [selectedDocuments, setSelectedDocuments] = useState<DocumentObject[]>(
    customObjectAssocations ?? [],
  );

  const updateDocumentObjectHandler = async (documentInfo: {
    documentIds: string[];
    documentProperties: Partial<DocumentObjectProperties>;
  }) => {
    await updateDocumentProperties(
      documentInfo.documentIds,
      documentInfo.documentProperties,
    );
  };

  const updateDocumentObjectMutation = getDocumentObjectMutation(
    updateDocumentObjectHandler,
    dealId,
  );

  const lastModifiedDate = documentObject.properties.fileLastModifiedDate
    ? format(parseISO(documentObject.properties.fileLastModifiedDate), 'P')
    : '';

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = await uploadFile(dealId, 'documents', file);
      successHandler('File successfully uploaded!');
      await updateDocumentProperties([documentObject.id], {
        s3FileName: fileName,
      });
    }
  };

  const uploadFileMutation = getDocumentObjectMutationForUploadProperties(
    uploadFileHandler,
    dealId,
    documentObject,
  );
  const clearAssociationList = useCallback(() => {
    setSelectedDocuments([]);
    if (customObjectAssocations?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: customObjectAssocations?.map((object) => object.id),
        documentProperties: {
          s3FileName: '',
          fileLastModifiedDate: '',
          status: statusOptions[0].hubspotName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customObjectAssocations]);

  const updateAssociationProperties = useCallback(
    (
      oldPropertyKey: string,
      oldPropertyValue: string | undefined,
      newProperties: Partial<DocumentObjectProperties>,
    ) => {
      const documentIdsToUpdate = customObjectAssocations
        ?.filter((document) =>
          Object.entries(document.properties).find(
            ([propertyKey, propertyValue]) =>
              propertyKey === oldPropertyKey &&
              propertyValue === oldPropertyValue,
          ),
        )
        .map((document) => document.id);

      if (documentIdsToUpdate?.length) {
        updateDocumentObjectMutation.mutate({
          documentIds: documentIdsToUpdate,
          documentProperties: newProperties,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customObjectAssocations],
  );

  useEffect(() => {
    if (customObjectAssocations) setSelectedDocuments(customObjectAssocations);
  }, [customObjectAssocations]);

  return (
    <>
      {/* TODO ADD COMMENT - Just a description of each table cell, like in the comment below */}
      {/* Colored Status Indicator */}
      <TableCell align="center" sx={statusColumnStyle}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            backgroundColor:
              documentObject.properties.status !==
                DOCUMENT_STATUSES.IQ_CONFIRMED &&
              documentObject.properties.status !==
                DOCUMENT_STATUSES.IR_CONFIRMED
                ? getStatusColor(documentObject.properties.status)
                : null,
            border: `4px solid ${getStatusColor(
              documentObject.properties.status,
            )}`,
            borderRadius: '50%',
            margin: '0 auto',
          }}
        />
      </TableCell>

      {/* TODO ADD COMMENT  */}
      <TableCell sx={documentNameColumnStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TableBoldedTypography
            sx={{ maxWidth: '70%', wordWrap: 'break-word' }}
            text={documentObject.properties.displayName}
          />
          {!isCustomObject && (
            <UploadButton mutationMethod={uploadFileMutation} />
          )}
        </Box>
        {!!documentObject.properties.s3FileName && (
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: documentObject.properties.s3FileName,
              fileType: 'documents',
            }}
            isUploading={uploadFileMutation.isLoading}
          />
        )}
      </TableCell>

      {/* TODO ADD COMMENT  */}
      <TableCell sx={uploadDateColumnStyle}>
        <Tooltip title={lastModifiedDate} enterDelay={0}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {lastModifiedDate}
          </Typography>
        </Tooltip>
      </TableCell>

      {/* TODO ADD COMMENT  */}
      <TableCell sx={rotateFileColumnStyle}>
        {!!documentObject.properties.s3FileName &&
          documentObject.properties.contentType &&
          allowedContentTypes(documentObject.properties.contentType) && (
            <RotateDropdownMenu
              dealId={dealId}
              fileName={documentObject.properties.s3FileName}
            />
          )}
      </TableCell>

      {/* TODO ADD COMMENT  */}
      {isCustomObject && !isLoadingDocumentObjects && (
        <TableCell sx={dataAssociationColumnStyle}>
          <DataAssociationsDropdownMenu
            dealId={dealId}
            documentObject={documentObject}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            clearAssociationList={clearAssociationList}
            updateDocumentObjectMutation={updateDocumentObjectMutation}
          />
        </TableCell>
      )}

      {/* TODO ADD COMMENT  */}
      <TableCell sx={dataStatusColumnStyle}>
        <FormControl sx={dataStatusColumnMenuButtonStyle} size="small">
          <Select
            value={documentObject.properties.status}
            onChange={(event) => {
              const otherPropertiesToUpdate =
                event.target.value === DOCUMENT_STATUSES.NOT_YET_RECIEVED
                  ? { s3FileName: '', fileLastModifiedDate: '' }
                  : { invalidReason: '' };
              if (isCustomObject) {
                if (event.target.value === DOCUMENT_STATUSES.NOT_YET_RECIEVED) {
                  clearAssociationList();
                } else {
                  updateAssociationProperties(
                    'status',
                    documentObject.properties.status,
                    { status: event.target.value, ...otherPropertiesToUpdate },
                  );
                }
              }
              updateDocumentObjectMutation.mutate({
                documentIds: [documentObject.id],
                documentProperties: {
                  status: event.target.value,
                  ...(!isCustomObject ? otherPropertiesToUpdate : undefined),
                },
              });
            }}
            displayEmpty
          >
            {statusOptions.map((statusOption) => (
              <MenuItem
                key={statusOption.hubspotName}
                value={statusOption.hubspotName}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '8px',
                      minWidth: '8px',
                      height: '8px',
                      backgroundColor:
                        statusOption.hubspotName !==
                          DOCUMENT_STATUSES.IQ_CONFIRMED &&
                        statusOption.hubspotName !==
                          DOCUMENT_STATUSES.IR_CONFIRMED
                          ? getStatusColor(statusOption.hubspotName)
                          : null,
                      border: `2px solid ${getStatusColor(
                        statusOption.hubspotName,
                      )}`,
                      borderRadius: '50%',
                      margin: '0 auto',
                    }}
                  />
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      paddingLeft: 1,
                      flexGrow: 1,
                    }}
                  >
                    {statusOption.displayName}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      {/* TODO ADD COMMENT  */}
      <TableCell sx={invalidReasonsColumnStyle}>
        <FormControl sx={invalidReasonsColumnMenuButtonStyle} size="small">
          {!isLoadingInvalidReasonOptions &&
            documentObject.properties.status ===
              DOCUMENT_STATUSES.NOT_YET_RECIEVED && (
              <>
                <Select
                  value={documentObject.properties.invalidReason ?? ''}
                  displayEmpty
                  onChange={(event) => {
                    updateAssociationProperties(
                      'invalidReason',
                      documentObject.properties.invalidReason,
                      { invalidReason: event.target.value },
                    );
                    updateDocumentObjectMutation.mutate({
                      documentIds: [documentObject.id],
                      documentProperties: {
                        invalidReason: event.target.value,
                      },
                    });
                  }}
                >
                  {invalidReasons?.map((reason) =>
                    reason.value === '' ? (
                      <MenuItem key="none" value="">
                        Select reason invalidated
                      </MenuItem>
                    ) : (
                      <MenuItem key={reason.value} value={reason.value}>
                        {reason.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </>
            )}
        </FormControl>
      </TableCell>

      {/* TODO ADD COMMENT  */}
      <TableCell align="center" sx={noteColumnStyle}>
        <EditDocumentModal documentObject={documentObject} dealId={dealId} />
      </TableCell>
    </>
  );
};
