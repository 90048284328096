import React, { FunctionComponent, useCallback } from 'react';
import { documentObjectsQuery } from 'dataLayer';
import { Autocomplete, Box, Checkbox, Chip, TextField } from '@mui/material';
import { DOCUMENT_STATUSES } from 'pages/Deals/Deal/DealDocuments';
import { years } from 'pages/Deals/Deal';
import { DocumentObject } from 'types';
import { UseMutationResult, useQuery } from 'react-query';
import { DocumentObjectProperties, S3File } from 'types/dealTypes';
import { filterAssociatedDocumentObjects } from '../TableCells/CSCDocumentTableCells';

interface IProps {
  dealId: string;
  file: S3File;
  selectedDocuments: DocumentObject[];
  setSelectedDocuments: (documents: DocumentObject[]) => void;
  updateDocumentObjectMutation: UseMutationResult<
    void,
    unknown,
    {
      documentIds: string[];
      documentProperties: Partial<DocumentObjectProperties>;
    },
    {
      previousDocumentObjects: DocumentObject[] | undefined;
    }
  >;
}

export const FileAssociationsDropdownMenu: FunctionComponent<IProps> = ({
  dealId,
  file,
  selectedDocuments,
  setSelectedDocuments,
  updateDocumentObjectMutation,
}) => {
  const { data: documentObjects } = useQuery(documentObjectsQuery(dealId));

  const customObjectAssocations = filterAssociatedDocumentObjects(
    documentObjects,
    file.name,
  );

  const updateAssociationList = useCallback(() => {
    // add: documents that are selected but not yet associated
    const documentIdsToAssociate = selectedDocuments
      .filter(
        (selected) =>
          !customObjectAssocations?.find(
            (associated) => associated.id === selected.id,
          ),
      )
      .map((document) => document.id);

    if (documentIdsToAssociate?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: documentIdsToAssociate,
        documentProperties: {
          s3FileName: file.name,
          status: DOCUMENT_STATUSES.PENDING_REVIEW,
        },
      });
    }

    // remove: documents were previously associated but no longer selected
    const documentIdsToUnassociate = customObjectAssocations
      ?.filter(
        (associated) =>
          !selectedDocuments.find((selected) => selected.id === associated.id),
      )
      .map((document) => document.id);

    if (documentIdsToUnassociate?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: documentIdsToUnassociate,
        documentProperties: {
          s3FileName: '',
          status: DOCUMENT_STATUSES.NOT_YET_RECIEVED,
          fileLastModifiedDate: '',
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customObjectAssocations, selectedDocuments]);

  const clearAssociationList = useCallback(() => {
    setSelectedDocuments([]);
    if (customObjectAssocations?.length) {
      updateDocumentObjectMutation.mutate({
        documentIds: customObjectAssocations?.map((object) => object.id),
        documentProperties: {
          s3FileName: '',
          fileLastModifiedDate: '',
          status: DOCUMENT_STATUSES.NOT_YET_RECIEVED,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customObjectAssocations]);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      size="small"
      limitTags={0}
      options={
        documentObjects?.filter((document) =>
          years.includes(document.properties.year),
        ) ?? []
      }
      value={selectedDocuments}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onClose={updateAssociationList}
      onChange={(event, value, reason) => {
        setSelectedDocuments(value);
        if (reason === 'clear') {
          clearAssociationList();
        }
      }}
      getOptionLabel={(option) => option.properties.displayName}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox sx={{ marginRight: 2 }} checked={selected} />
          {option.properties.displayName}
        </li>
      )}
      renderTags={(tagValue, getTagProps) => (
        <Box>
          <Chip
            {...getTagProps({ index: 0 })}
            onDelete={undefined}
            size="small"
            label={tagValue?.[0].properties.displayName}
          />
          {tagValue.length > 1 && (
            <Chip size="small" label={`+${tagValue.length - 1}`} />
          )}
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder="Data Set(s) Association" />
      )}
    />
  );
};
