import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  text: string;
}

export const TableHeaderTypography: FunctionComponent<IProps> = ({ text }) => (
  <Typography fontSize="16px" color="portalPalettes.darkgray" fontWeight="500">
    {text}
  </Typography>
);
