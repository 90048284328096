import { QueryClient, QueryKey } from 'react-query';

export const invalidateQueryIfNotMutating = (
  qc: QueryClient,
  queryKey: QueryKey,
) => {
  const mutatingCount = qc.isMutating({
    mutationKey: queryKey,
  });

  if (mutatingCount === 1) {
    qc.invalidateQueries(queryKey);
  }
};

export const generateDealsQueryKey = (prefix: string) => [
  'data',
  'deals',
  prefix,
];

export const generateSolutionsDealQueryKey = (pagination: number) => [
  'data',
  'solutions',
  'deals',
  pagination,
];

export const generateDealObjectQueryKey = (dealId: string) => [
  'data',
  'dealProperties',
  dealId,
];

export const generateDocumentObjectsQueryKey = (dealId: string) => [
  'data',
  'documentProperties',
  dealId,
];

export const generateFilesQueryKey = (dealId: string, fileType: string) => [
  'data',
  'files',
  fileType,
  dealId,
];

export const generateNotesQueryKey = (dealId: string) => [
  'data',
  'notes',
  dealId,
];

export const generateTasksQueryKey = (dealId: string) => [
  'data',
  'tasks',
  dealId,
];

export const generateBqfDisplayNamesQueryKey = () => [
  'data',
  'bqfDisplayNames',
];

export const generateInvalidReasonsQueryKey = () => ['data', 'invalidReasons'];

export const generate2848FilesQueryKey = (dealId: string) => [
  'data',
  '2848Form',
  dealId,
];

export const generateTaxEngagementFilesQueryKey = (dealId: string) => [
  'data',
  'taxEngagement',
  dealId,
];

export const generateCreditObjectsQueryKey = (dealId: string) => [
  'data',
  'credits',
  dealId,
];

export const COGNITO_QUERY_KEY = ['cognito'];
export const COGNITO_CURRENT_USER_QUERY_KEY = ['cognito', 'currentUser'];
export const COGNITO_IS_AUTHENTICATED_QUERY_KEY = [
  'cognito',
  'isAuthenticated',
];
