import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { DownloadButton } from 'components/Buttons';
import { DealHeader, Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { FileRequestModal } from 'components/Modals';
import { TableHeaderTypography } from 'components/Typography';
import { filesQuery, useUserGroups } from 'dataLayer';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router';
import { format, parseISO } from 'date-fns';
import { useAsync } from '@react-hookz/web';
import { getZippedFiles } from 'api';
import { errorHandler } from 'helpers';
import { tasksQuery } from 'dataLayer/Deal/get';

interface IProps {
  dealId: string;
}

const FileRequestsTable: FunctionComponent<IProps> = ({ dealId }) => {
  const {
    data: filesForFileRequests,
    isLoading: isLoadingFilesForFileRequests,
  } = useQuery(filesQuery(dealId, 'fileRequests'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          File Request Files
        </Typography>
      </Box>
      {isLoadingFilesForFileRequests ? (
        <LoadingIndicator />
      ) : (
        <TableContainer
          sx={{
            marginTop: 4,
          }}
        >
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell>
                  <TableHeaderTypography text="File Name" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Upload Date" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filesForFileRequests?.map((fileObject) => (
                <TableRow>
                  <TableCell>
                    <DownloadButton
                      dealId={dealId}
                      fileInformation={{
                        fileName: fileObject.name,
                        fileType: 'fileRequests',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {fileObject.lastModified
                      ? format(parseISO(fileObject.lastModified), 'P')
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const HubspotTasksTable: FunctionComponent<IProps> = ({ dealId }) => {
  const { data: tasks, isLoading: isLoadingTasks } = useQuery(
    tasksQuery(dealId),
  );

  return (
    <Box
      sx={{
        marginBottom: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          File Request History
        </Typography>
      </Box>
      {isLoadingTasks ? (
        <LoadingIndicator />
      ) : (
        <TableContainer
          sx={{
            marginTop: 4,
          }}
        >
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell>
                  <TableHeaderTypography text="Document Type" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Notes" />
                </TableCell>
                <TableCell>
                  <TableHeaderTypography text="Created Date" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks?.map((task) => (
                <TableRow>
                  <TableCell>{task.properties.subject}</TableCell>
                  <TableCell>{task.properties.body}</TableCell>
                  <TableCell>
                    {format(parseISO(task.properties.createDate), 'P')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export const FileRequests: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const userGroups = useUserGroups();

  const [downloadZipState, downloadZipAction] = useAsync(async () => {
    try {
      const file = await getZippedFiles(dealId, 'fileRequests');
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      {userGroups?.includes('cpa') && <FileRequestModal dealId={dealId} />}
      <Button
        disabled={downloadZipState.status === 'loading'}
        variant="outlined"
        sx={{
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '18px',
        }}
        onClick={downloadZipAction.execute}
      >
        {downloadZipState.status === 'loading' ? (
          <CircularProgress size={20} />
        ) : (
          'Download All Files'
        )}
      </Button>
      <FileRequestsTable dealId={dealId} />
      <HubspotTasksTable dealId={dealId} />
    </>
  );
};
