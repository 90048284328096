import axios, { AxiosError } from 'axios';
import { getUserToken } from 'dataLayer';
import {
  CreditObject,
  DealObject,
  DocumentObject,
  DealNoteObject,
  GetFilesLambdaResponse,
  InvalidReason,
  S3File,
} from 'types';
import {
  CreditObjectProperties,
  DealObjectProperties,
  DocumentObjectProperties,
  DealNoteProperties,
  CreateDealTaskProperties,
  DealTaskObject,
  SolutionsDealsResponse,
} from 'types/dealTypes';
import { GetDealsSummaryResponse } from 'types/lambdaResponseTypes';

const FILES_URL = '/files';
const ZIP_URL = '/zip';
const GET_DEALS_URL = '/deals';
const ROTATE_FILE_URL = '/rotate';
const ARCHIVE_FILE_URL = '/archive';
const COPY_FILE_URL = '/copy';
const HUBSPOT_DEALS_URL = '/hubspot/deals';
const HUBSPOT_DOCUMENTS_URL = '/hubspot/documents';
const HUBSPOT_INVALIDREASONS_URL = '/hubspot/invalidreasons';
const HUBSPOT_BQF_DISPLAY_NAMES_URL = '/hubspot/bqfdisplaynames';
const HUBSPOT_CREDITS_URL = '/hubspot/credits';
const HUBSPOT_NOTES_URL = '/hubspot/notes';
const HUBSPOT_TASKS_URL = '/hubspot/tasks';

// TODO - when these get refactored to objects,
// we should add comments explaining a bit of high level context
// (e.g. File: this is a file that was uploaded by user in the ____ portal to S3, yadda yadda)

const lambdaAxios = axios.create({
  baseURL: `https://${process.env.REACT_APP_BACKEND_URL}`,
});

const generateHeaders = (token: string) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
});

const getUniqueS3ObjectPath = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  fileType: string,
  index = 0,
): Promise<{ fileName: string; putUrl: string; getUrl: string }> => {
  const fileNameSplit = fileName.split('.');
  const fileExtension = fileNameSplit.pop();
  const uniqueKey = index
    ? `${fileNameSplit.join('.')} (${index}).${fileExtension}`
    : fileName;

  try {
    const encodedFilename = encodeURIComponent(uniqueKey);
    const response = await lambdaAxios.put(
      `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
      undefined,
      generateHeaders(token!),
    );
    return { fileName: uniqueKey, ...response.data };
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 409) {
      return getUniqueS3ObjectPath(
        token,
        dealId,
        fileName,
        fileType,
        index + 1,
      );
    }
    throw error;
  }
};

export const getFilesForDeal = async (dealId: string, fileType: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<GetFilesLambdaResponse>(
    `${FILES_URL}/${dealId}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data.files;
};

export const getFile = async (
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.get<S3File>(
    `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getZippedFiles = async (dealId: string, fileType: string) => {
  const token = await getUserToken();
  if (!token) return null;

  const response = await lambdaAxios.get<{ url: string }>(
    `${ZIP_URL}/${dealId}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data;
};

export const uploadFile = async (
  dealId: string,
  fileType: string,
  file: File,
) => {
  const token = await getUserToken();
  if (!token) return '';
  const response = await getUniqueS3ObjectPath(
    token,
    dealId,
    file.name,
    fileType,
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });
  return response.fileName;
};

export const uploadDASheet = async (
  dealId: string,
  fileType: string,
  file: File,
) => {
  const token = await getUserToken();
  if (!token) return '';
  const extension = file.name.split('.').pop();
  const fileName = `Data Agg Sheet - ${dealId}.${extension}`;
  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.put(
    `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}&allowOverride=true`,
    undefined,
    generateHeaders(token!),
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.data.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });

  return fileName;
};

export const upload941xFile = async (
  dealId: string,
  fileType: string,
  file: File,
) => {
  const token = await getUserToken();
  if (!token) return '';
  const extension = file.name.split('.').pop();
  const fileName = `Amended941x - ${dealId}.${extension}`;
  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.put(
    `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}&allowOverride=true`,
    undefined,
    generateHeaders(token!),
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.data.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });

  return fileName;
};

export const getDealsSummary = async (prefix: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<GetDealsSummaryResponse>(
    `${GET_DEALS_URL}?prefix=${prefix}`,
    generateHeaders(token),
  );
  return response.data.deals;
};

export const rotateFile = async (
  dealId: string,
  fileName: string,
  rotationInDegrees: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.post(
    `${ROTATE_FILE_URL}/${dealId}/${encodedFilename}/${rotationInDegrees}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const getInvalidReasons = async () => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<InvalidReason[]>(
    `${HUBSPOT_INVALIDREASONS_URL}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getDealProperties = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<DealObject>(
    `${HUBSPOT_DEALS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const updateDealProperties = async (
  dealIds: string[],
  properties: Partial<DealObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (dealIds.length === 0) return;

  const url = `${HUBSPOT_DEALS_URL}?hubspotObjectId=${dealIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, properties, generateHeaders(token));
};

export const getDocumentProperties = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];
  const response = await lambdaAxios.get<DocumentObject[]>(
    `${HUBSPOT_DOCUMENTS_URL}/${dealId}`,
    generateHeaders(token),
  );

  return response.data;
};

export const updateDocumentProperties = async (
  documentObjectIds: string[],
  properties: Partial<DocumentObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (documentObjectIds.length === 0) return;

  const url = `${HUBSPOT_DOCUMENTS_URL}?hubspotObjectId=${documentObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, properties, generateHeaders(token));
};

export const getBqfDisplayNames = async () => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<DealObject>(
    `${HUBSPOT_BQF_DISPLAY_NAMES_URL}`,
    generateHeaders(token),
  );
  return response.data;
};

export const archiveFile = async (
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.put(
    `${ARCHIVE_FILE_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const getCreditObjects = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<CreditObject[]>(
    `${HUBSPOT_CREDITS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const createCreditObject = async (
  dealId: string,
  creditProperties: Partial<CreditObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.put(
    `${HUBSPOT_CREDITS_URL}/${dealId}`,
    creditProperties,
    generateHeaders(token),
  );
};

export const deleteCreditObject = async (dealId: string, creditId: string) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.delete(
    `${HUBSPOT_CREDITS_URL}/${dealId}/${creditId}`,
    generateHeaders(token),
  );
};

export const updateCreditObjects = async (
  creditObjectIds: string[],
  creditProperties: Partial<CreditObjectProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;
  if (creditObjectIds.length === 0) return;

  const url = `${HUBSPOT_CREDITS_URL}?hubspotObjectId=${creditObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, creditProperties, generateHeaders(token));
};

export const createDealNoteObject = async (
  dealId: string,
  dealNoteProperties: Partial<DealNoteProperties>,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.post(
    `${HUBSPOT_NOTES_URL}/${dealId}`,
    dealNoteProperties,
    generateHeaders(token),
  );
};

export const getDealNotes = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<DealNoteObject[]>(
    `${HUBSPOT_NOTES_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getDealTasks = async (dealId: string) => {
  const token = await getUserToken();
  if (!token) return [];

  const response = await lambdaAxios.get<DealTaskObject[]>(
    `${HUBSPOT_TASKS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const createDealTaskObject = async (
  dealId: string,
  dealTaskProperties: CreateDealTaskProperties,
) => {
  const token = await getUserToken();
  if (!token) return;

  await lambdaAxios.post(
    `${HUBSPOT_TASKS_URL}/${dealId}`,
    dealTaskProperties,
    generateHeaders(token),
  );
};

export const copyFile = async (
  dealId: string,
  fileName: string,
  sourceFileType: string,
  destinationFileType: string,
) => {
  const token = await getUserToken();
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.put(
    `${COPY_FILE_URL}/${dealId}/${encodedFilename}?sourceFileType=${sourceFileType}&destinationFileType=${destinationFileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const getFilteredDealsForSolutions = async (pagination: number) => {
  const token = await getUserToken();
  if (!token) return undefined;

  const response = await lambdaAxios.get<SolutionsDealsResponse>(
    `${GET_DEALS_URL}/solutions?pagination=${pagination}`,
    generateHeaders(token),
  );
  return response.data;
};

export const get941xZippedFiles = async (dealIds: string[]) => {
  const token = await getUserToken();
  if (!token) return undefined;
  if (dealIds.length === 0) return undefined;

  const url = `${ZIP_URL}/bulk941x?hubspotObjectId=${dealIds.join(
    '&hubspotObjectId=',
  )}`;

  const response = await lambdaAxios.get<{ url: string }>(
    url,
    generateHeaders(token),
  );
  return response.data;
};
