import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useAsync } from '@react-hookz/web';
import { getZippedFiles } from 'api';
import { updateDealProperties } from 'api/lambda';
import { RejectCaseModal } from 'components/Modals';
import { dealPropertiesQuery } from 'dataLayer';
import { getDealObjectMutation } from 'dataLayer/Deal/mutations';
import { errorHandler } from 'helpers';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  generatePath,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { PATHS } from 'Router';
import { DealObjectProperties } from 'types';

interface IRejectCaseProps {
  rejectCaseDate: string | undefined;
  dealId: string;
}

const RejectCase: FunctionComponent<IRejectCaseProps> = ({
  rejectCaseDate,
  dealId,
}) =>
  rejectCaseDate ? (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        fontSize="18px"
        color="portalPalettes.darkgray"
        fontWeight="700"
      >
        File Rejected Date
      </Typography>
      <Typography
        fontSize="18px"
        color="portalPalettes.darkgray"
        fontWeight="700"
      >
        {
          new Date(rejectCaseDate)
            .toLocaleString('en-US', { timeZone: 'UTC' })
            .split(',')[0]
        }
      </Typography>
    </Box>
  ) : (
    <RejectCaseModal dealId={dealId} />
  );

interface IProps {
  dealId: string;
}

export const DealHeader: FunctionComponent<IProps> = ({ dealId }) => {
  const { pathname: currentPath } = useLocation();

  const { data: dealProperties, isLoading: isDealPropertiesLoading } = useQuery(
    dealPropertiesQuery(dealId),
  );

  const initializeDate = (formattedDate: string) =>
    new Date(
      new Date(formattedDate).valueOf() +
        60000 * new Date().getTimezoneOffset(), // 60000 is the number of milliseconds in a minute, which is the unit of the timezone offset
    );

  const formatDate = (date: Moment | null) => {
    if (!date) return undefined;
    return date.format('YYYY-MM-DD');
  };

  const cpaCompletionDate = useMemo(
    () =>
      dealProperties?.properties.estimatedCPACompletionDate
        ? initializeDate(dealProperties?.properties.estimatedCPACompletionDate)
        : null,
    [dealProperties?.properties.estimatedCPACompletionDate],
  );

  const [completionDate, setCompletionDate] = useState<Moment | null>(
    moment(cpaCompletionDate),
  );

  useEffect(() => {
    if (cpaCompletionDate) setCompletionDate(moment(cpaCompletionDate));
  }, [cpaCompletionDate]);

  const updateDealObjectHandler = async (
    newDealProperties: Partial<DealObjectProperties>,
  ) => {
    await updateDealProperties([dealId], newDealProperties);
  };

  const updateDealObjectMutation = getDealObjectMutation(
    updateDealObjectHandler,
    dealId,
  );

  const [downloadZipState, downloadZipAction] = useAsync(async () => {
    try {
      const file = await getZippedFiles(dealId, 'documents');
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  const getPageName = () => {
    if (currentPath.includes('cpa')) {
      return 'Manage Case';
    }
    if (currentPath.includes('documents')) {
      return 'Manage Documents';
    }
    return 'Refund Application';
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginTop: 2,
          '& hr': {
            mx: 1,
          },
        }}
      >
        <Typography sx={{ marginRight: 1 }} fontSize="24px" color="#24205A">
          {getPageName()}
        </Typography>

        <Typography
          sx={{ marginX: 1, marginTop: 1 }}
          fontSize="16px"
          color="#5F61AB"
        >
          {dealProperties?.properties.companyName
            ? `${dealProperties.properties.companyName} | `
            : ''}
          Application ID: {dealId}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginY: 2,
          '& hr': {
            mx: 1,
          },
        }}
      >
        {!currentPath.includes('overview') &&
          !currentPath.includes('file-requests') && (
            <>
              <Button
                variant="outlined"
                component={RouterLink}
                to={generatePath(PATHS.BQF, { dealId })}
                sx={{
                  marginRight: 2,
                  color: 'portalPalettes.green',
                  borderColor: 'portalPalettes.green',
                  fontSize: '18px',
                }}
              >
                Company Information
              </Button>
              <Button
                disabled={downloadZipState.status === 'loading'}
                variant="outlined"
                sx={{
                  marginRight: 2,
                  color: 'portalPalettes.green',
                  borderColor: 'portalPalettes.green',
                  fontSize: '18px',
                }}
                onClick={downloadZipAction.execute}
              >
                {downloadZipState.status === 'loading' ? (
                  <CircularProgress size={20} />
                ) : (
                  'Download Case Files'
                )}
              </Button>
              <Button
                variant="outlined"
                component={RouterLink}
                to={generatePath(PATHS.FILE_REQUESTS, { dealId })}
                sx={{
                  marginRight: 2,
                  color: 'portalPalettes.green',
                  borderColor: 'portalPalettes.green',
                  fontSize: '18px',
                }}
              >
                File Requests
              </Button>
            </>
          )}
        {currentPath.includes('cpa') && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-between',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="CPA Completion Date"
                value={completionDate?.isValid() ? completionDate : null}
                onChange={(newValue) => {
                  updateDealObjectMutation.mutate({
                    estimatedCPACompletionDate: formatDate(newValue),
                  });
                  setCompletionDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {!isDealPropertiesLoading ? (
              <RejectCase
                rejectCaseDate={
                  dealProperties?.properties.taxAttorneyRejectedDate
                }
                dealId={dealId}
              />
            ) : null}
          </Box>
          // <Button
          //   variant="outlined"
          //   sx={{
          //     marginRight: 2,
          //     color: 'portalPalettes.green',
          //     borderColor: 'portalPalettes.green',
          //     fontSize: '18px',
          //   }}
          //   // TODO: onClick
          // >
          //   Mark Case Complete
          // </Button>
        )}
      </Box>
    </>
  );
};
