import { SxProps, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  text: string;
  sx?: SxProps<Theme>;
}

export const TableCommonTypography: FunctionComponent<IProps> = ({
  text,
  sx = [],
}) => (
  <Typography
    fontSize="14px"
    color="portalPalettes.lightgray"
    sx={[...(Array.isArray(sx) ? sx : [sx])]}
  >
    {text}
  </Typography>
);
