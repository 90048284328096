import { SxProps, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  text: string;
  sx?: SxProps<Theme>;
}

export const TableBoldedTypography: FunctionComponent<IProps> = ({
  text,
  sx = [],
}) => (
  <Typography
    fontSize="16px"
    color="portalPalettes.darkgray"
    fontWeight="700"
    sx={[...(Array.isArray(sx) ? sx : [sx])]}
  >
    {text}
  </Typography>
);
