/* eslint-disable react-hooks/rules-of-hooks */
import {
  errorHandler,
  generateDocumentObjectsQueryKey,
  invalidateQueryIfNotMutating,
} from 'helpers';
import { ChangeEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DocumentObject } from 'types';
import { DocumentObjectProperties } from 'types/dealTypes';

export const getDocumentObjectMutation = (
  updateDocumentObjectHandler: (documentInfo: {
    documentIds: string[];
    documentProperties: Partial<DocumentObjectProperties>;
  }) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    {
      documentIds: string[];
      documentProperties: Partial<DocumentObjectProperties>;
    },
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(updateDocumentObjectHandler, {
    mutationKey,
    onMutate: ({ documentIds, documentProperties: newProperties }) => {
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(dealId));
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        (oldDocumentObjects: DocumentObject[] | undefined) => {
          const documentObjectsBeingUpdated = oldDocumentObjects?.filter(
            (docObj) => documentIds.includes(docObj.id),
          );
          if (documentObjectsBeingUpdated?.length) {
            documentObjectsBeingUpdated.forEach((documentObject) => {
              // eslint-disable-next-line no-param-reassign
              documentObject.properties = {
                ...documentObject.properties,
                ...newProperties,
              };
            });
          }
          return oldDocumentObjects ?? [];
        },
      );
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const getDocumentObjectMutationForUploadProperties = (
  updateDocumentObjectHandler: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>,
  dealId: string,
  currentDocumentObject: DocumentObject,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDocumentObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(updateDocumentObjectHandler, {
    mutationKey,
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(dealId));
      if (fileName) {
        qc.setQueryData(
          generateDocumentObjectsQueryKey(dealId),
          (oldDocumentObjects: DocumentObject[] | undefined) => {
            const documentObjectToOverwrite = oldDocumentObjects?.find(
              (docObj) => docObj.id === currentDocumentObject.id,
            );
            if (documentObjectToOverwrite) {
              documentObjectToOverwrite.properties = {
                ...documentObjectToOverwrite.properties,
                s3FileName: fileName,
                fileLastModifiedDate: new Date().toISOString(),
                contentType: '',
              };
            }
            return oldDocumentObjects ?? [];
          },
        );
      }
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      setTimeout(() => {
        // Need to wait until convertExcelToCsv finishes
        const mutatingCount = qc.isMutating({
          mutationKey,
        });

        if (mutatingCount === 0) {
          // I believe onSettled counts as the mutation running. Since onSettled isn't waiting on setTimeout,
          // by the time the callback is called the mutation is considered done so mutatingCount === 0
          qc.invalidateQueries(mutationKey);
        }
      }, 2500);
    },
  });
};
