import {
  Box,
  Button,
  Checkbox,
  Modal,
  TableCell,
  Typography,
} from '@mui/material';
import { useDebouncedCallback } from '@react-hookz/web';
import { createCreditObject, updateCreditObjects } from 'api';
import { deleteCreditObject } from 'api/lambda';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { TableBoldedTypography } from 'components/Typography';
import {
  getCreateCreditObjectMutation,
  getCreditObjectMutation,
  getDeleteCreditObjectMutation,
  creditObjectsQuery,
} from 'dataLayer';
import {
  checkboxColumnStyle,
  creditObjectColumnStyle,
  creditObjectNameColumnStyle,
} from 'pages/Deals/DealCPA';
import { FocusEvent, FunctionComponent, SyntheticEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { CreditObjectProperties } from 'types';

interface IProps {
  dealId: string;
  creditObjectType: { displayName: string; hubspotName: string };
}

export const CPACreditTableCells: FunctionComponent<IProps> = ({
  dealId,
  creditObjectType,
}) => {
  const { data: creditObjects } = useQuery(creditObjectsQuery(dealId));
  const creditObjectForThisForm = creditObjects?.find(
    (creditObject) =>
      creditObject.properties.name === creditObjectType.hubspotName,
  );

  const createCreditObjectHandler = async (
    creditProperties: Partial<CreditObjectProperties>,
  ) => {
    await createCreditObject(dealId, creditProperties);
  };

  const createCreditObjectMutation = getCreateCreditObjectMutation(
    createCreditObjectHandler,
    dealId,
  );

  const deleteCreditObjectHandler = async (creditId: string) => {
    if (creditObjectForThisForm) await deleteCreditObject(dealId, creditId);
  };

  const deleteCreditObjectMutation = getDeleteCreditObjectMutation(
    deleteCreditObjectHandler,
    dealId,
  );

  const updateCreditObjectHandler = async (
    creditProperties: Partial<CreditObjectProperties>,
  ) => {
    if (creditObjectForThisForm) {
      await updateCreditObjects([creditObjectForThisForm.id], creditProperties);
    }
  };

  const updateCreditObjectMutation = getCreditObjectMutation(
    updateCreditObjectHandler,
    dealId,
  );

  const updateEstimatedRefundAmount = useDebouncedCallback(
    (input) =>
      updateCreditObjectMutation.mutate({
        name: creditObjectForThisForm?.properties.name,
        estimatedRefundAmount: input,
      }),
    [],
    1000,
    0,
  );

  const [openModal, setOpenModal] = useState(false);
  const handleClose = (event: SyntheticEvent, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  return (
    <>
      {/* TODO ADD COMMENT - Just a description of each table cell */}
      {/* TODO ADD COMMENT */}
      <TableCell sx={checkboxColumnStyle.containerStyle}>
        {createCreditObjectMutation.isLoading ||
        deleteCreditObjectMutation.isLoading ||
        creditObjectForThisForm?.id === 'optimisticId' ? (
          <LoadingIndicator />
        ) : (
          <Checkbox
            color="default"
            checked={!!creditObjectForThisForm ?? false}
            onChange={(event) => {
              if (event.target.checked) {
                createCreditObjectMutation.mutate({
                  name: creditObjectType.hubspotName,
                });
              } else {
                setOpenModal(true);
              }
            }}
          />
        )}
      </TableCell>

      {/* TODO ADD COMMENT */}
      <TableCell
        sx={{
          ...creditObjectNameColumnStyle.containerStyle,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TableBoldedTypography text={creditObjectType.displayName} />
        </Box>
      </TableCell>

      {/* TODO ADD COMMENT */}
      <TableCell
        sx={{
          ...creditObjectColumnStyle.containerStyle,
        }}
      >
        {!!creditObjectForThisForm && !createCreditObjectMutation.isLoading && (
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                borderRadius: '4px 0 0 4px',
                borderStyle: 'solid',
                borderWidth: '1px 0 1px 1px',
                borderColor: '#0000003B',
                height: 'inherit',
                paddingY: 1,
                paddingX: 1.5,
              }}
            >
              $
            </Box>
            <NumberFormat
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
              style={{
                borderRadius: '0 4px 4px 0',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#0000003B',
                paddingLeft: '8px',
              }}
              type="text"
              defaultValue={
                creditObjectForThisForm?.properties.estimatedRefundAmount ?? 0
              }
              onValueChange={(values: { value: string }) =>
                updateEstimatedRefundAmount(values.value)
              }
              onFocus={(event: FocusEvent<HTMLInputElement>) =>
                event.target?.select()
              }
            />
          </Box>
        )}
      </TableCell>

      {/* TODO ADD COMMENT */}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="delete-credit-object-modal-title"
        aria-describedby="delete-credit-object-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography
            id="delete-credit-object-modal-title"
            textAlign="center"
            fontSize="18px"
          >
            Unchecking this will delete the file and qualifying credits for this
            form. Do you wish to continue?
          </Typography>
          <Box sx={{ marginTop: 2, marginBotton: 1, display: 'flex' }}>
            <Button
              sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="contained"
              color="error"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="outlined"
              color="success"
              onClick={() => {
                if (creditObjectForThisForm)
                  deleteCreditObjectMutation.mutate(creditObjectForThisForm.id);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
