/**
 * The total of all widths in containerStyle must equal 80vw
 * This is because there is already padding on the left and right side of the page that equals 20vw
 */
export const sharedContainerStyle = {
  padding: 1,
  boxSizing: 'border-box',
};

export const statusColumnStyle = {
  containerStyle: {
    maxWidth: '4vw',
    minWidth: '4vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '4vw',
    minWidth: '4vw',
    ...sharedContainerStyle,
  },
};
export const documentNameColumnStyle = {
  containerStyle: {
    maxWidth: '24vw',
    minWidth: '24vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '20vw',
    minWidth: '20vw',
    ...sharedContainerStyle,
  },
  fileStyle: {
    maxWidth: '30vw',
    minWidth: '30vw',
    ...sharedContainerStyle,
  },
};
export const uploadDateColumnStyle = {
  containerStyle: {
    maxWidth: '7vw',
    minWidth: '7vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '7vw',
    minWidth: '7vw',
    ...sharedContainerStyle,
  },
  fileStyle: {
    maxWidth: '7vw',
    minWidth: '7vw',
    ...sharedContainerStyle,
  },
};
export const rotateFileColumnStyle = {
  containerStyle: {
    maxWidth: '8vw',
    minWidth: '8vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '8vw',
    minWidth: '8vw',
    ...sharedContainerStyle,
  },
  menuButtonStyle: {
    maxWidth: '100%',
    minWidth: '100%',
    boxSizing: 'border-box',
  },
};

export const dataAssociationColumnStyle = {
  containerStyle: {},
  customObjectStyle: {
    maxWidth: '20vw',
    minWidth: '20vw',
    ...sharedContainerStyle,
  },
  fileStyle: {
    maxWidth: '40vw',
    minWidth: '40vw',
    ...sharedContainerStyle,
  },
};
export const dataStatusColumnStyle = {
  containerStyle: {
    maxWidth: '13vw',
    minWidth: '13vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '5vw',
    minWidth: '5vw',
    ...sharedContainerStyle,
  },
  menuButtonStyle: {
    maxWidth: '100%',
    minWidth: '100%',
    boxSizing: 'border-box',
  },
};
export const invalidReasonsColumnStyle = {
  containerStyle: {
    maxWidth: '18vw',
    minWidth: '18vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '10vw',
    minWidth: '10vw',
    ...sharedContainerStyle,
  },
  menuButtonStyle: {
    maxWidth: '100%',
    minWidth: '100%',
    boxSizing: 'border-box',
  },
};

export const noteColumnStyle = {
  containerStyle: {
    maxWidth: '4vw',
    minWidth: '4vw',
    ...sharedContainerStyle,
  },
  customObjectStyle: {
    maxWidth: '4vw',
    minWidth: '4vw',
    ...sharedContainerStyle,
  },
};

export const getDocumentTableStyles = (
  tableType?: 'customObjectStyle' | 'containerStyle' | 'fileStyle',
) => {
  const style = tableType ?? 'containerStyle';

  if (style === 'fileStyle') {
    return {
      documentNameColumnStyle: documentNameColumnStyle[style],
      uploadDateColumnStyle: uploadDateColumnStyle[style],
      dataAssociationColumnStyle: dataAssociationColumnStyle[style],
    };
  }

  return {
    statusColumnStyle: statusColumnStyle[style],
    documentNameColumnStyle: documentNameColumnStyle[style],
    uploadDateColumnStyle: uploadDateColumnStyle[style],
    rotateFileColumnStyle: rotateFileColumnStyle[style],
    rotateFileColumnMenuButtonStyle: rotateFileColumnStyle.menuButtonStyle,
    dataAssociationColumnStyle: dataAssociationColumnStyle[style],
    dataStatusColumnStyle: dataStatusColumnStyle[style],
    dataStatusColumnMenuButtonStyle: dataStatusColumnStyle.menuButtonStyle,
    invalidReasonsColumnStyle: invalidReasonsColumnStyle[style],
    invalidReasonsColumnMenuButtonStyle:
      invalidReasonsColumnStyle.menuButtonStyle,
    noteColumnStyle: noteColumnStyle[style],
  };
};
