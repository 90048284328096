import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { uploadFile } from 'api';
import { updateDealProperties, uploadDASheet } from 'api/lambda';
import { UploadButton } from 'components/Buttons';
import { Footer } from 'components/Footer';
import { DealHeader, Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import {
  CSCDocumentTableCells,
  CSCFileTableCells,
} from 'components/TableCells';
import {
  TableCommonTypography,
  TableHeaderTypography,
} from 'components/Typography';
import {
  documentObjectsQuery,
  filesQuery,
  getS3FileUploadMutation,
} from 'dataLayer';
import { errorHandler, successHandler } from 'helpers';
import { ChangeEvent, FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { DocumentObject } from 'types/dealTypes';
import { getDocumentTableStyles } from './documentsTableStyles';

export const years = [2021, 2020, 2019];

export const DOCUMENT_STATUSES = {
  NOT_YET_RECIEVED: 'Not Yet Received',
  PENDING_REVIEW: 'Pending IR Review',
  IQ_CONFIRMED: 'IQ Confirmed',
  IR_CONFIRMED: 'Confirmed by IR',
  IQ_QC_CONFIRMED: 'IQ QC Confirmed',
  IR_QC_CONFIRMED: 'QC Confirmed',
};

export const DealDocuments: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const { data: files } = useQuery(filesQuery(dealId, 'documents'));

  const { data: documentObjects, isLoading: isLoadingDocumentObjects } =
    useQuery(documentObjectsQuery(dealId));

  const getStatusPriority = (status: string) => {
    switch (status) {
      case DOCUMENT_STATUSES.PENDING_REVIEW:
        return 1;
      case DOCUMENT_STATUSES.NOT_YET_RECIEVED:
        return 2;
      case DOCUMENT_STATUSES.IQ_CONFIRMED:
        return 3;
      case DOCUMENT_STATUSES.IR_CONFIRMED:
        return 4;
      case DOCUMENT_STATUSES.IQ_QC_CONFIRMED:
        return 5;
      default:
        return 6;
    }
  };

  const sortByStatus = (
    documentObject1: DocumentObject,
    documentObject2: DocumentObject,
  ) => {
    const documentObject1StatusPriority = getStatusPriority(
      documentObject1.properties.status,
    );
    const documentObject2StatusPriority = getStatusPriority(
      documentObject2.properties.status,
    );
    return documentObject1StatusPriority - documentObject2StatusPriority;
  };

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const promises = [];
    const numberOfFiles = event.target.files?.length ?? 0;
    for (let i = 0; i < numberOfFiles; i += 1) {
      const file = event.target.files?.item(i);
      if (file) {
        promises.push(uploadFile(dealId, 'documents', file));
      }
    }
    await Promise.all(promises);
    successHandler(
      `${numberOfFiles > 1 ? 'Files' : 'File'} successfully uploaded!`,
    );
  };

  const uploadDASheetHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file) {
      await uploadDASheet(dealId, 'documents', file);
      await updateDealProperties([dealId], {
        aggregationCompleteMarker: 'Yes',
      });
      successHandler('Data Aggregation Sheet successfully uploaded!');
    } else {
      errorHandler('File failed to upload');
    }
  };

  const uploadFileMutation = getS3FileUploadMutation(
    uploadFileHandler,
    dealId,
    'documents',
  );
  const uploadDASheetMutation = getS3FileUploadMutation(
    uploadDASheetHandler,
    dealId,
    'documents',
  );

  const renderSubHeader = () => (
    <>
      <DealHeader dealId={dealId} />
      <Typography fontSize="25px" fontWeight="800" marginBottom={2}>
        New Uploads
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Typography fontSize="15px">
          New uploads ready for review, association and status confirmation.
        </Typography>
        <Typography fontSize="16px" color="#777777">
          All uploads and edits are saved automatically
        </Typography>
      </Box>
    </>
  );

  const renderFilesList = (
    label: string | number,
    documents?: DocumentObject[],
  ) => {
    const isCustomObject = label === 'Other';

    const {
      dataStatusColumnStyle,
      documentNameColumnStyle,
      dataAssociationColumnStyle,
      invalidReasonsColumnStyle,
      noteColumnStyle,
      rotateFileColumnStyle,
      statusColumnStyle,
      uploadDateColumnStyle,
    } = getDocumentTableStyles('customObjectStyle');

    return (
      <>
        <Typography
          fontSize="25px"
          fontWeight="800"
          marginTop={4}
          marginBottom={2}
        >
          {label}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={statusColumnStyle} />
                <TableCell sx={documentNameColumnStyle}>
                  <TableHeaderTypography text="Document Name" />
                  <TableCommonTypography
                    text="Upload File Name"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell sx={uploadDateColumnStyle}>
                  <TableHeaderTypography text="Uploaded" />
                </TableCell>
                <TableCell sx={rotateFileColumnStyle}>
                  <TableHeaderTypography text="Rotate" />
                  <TableCommonTypography
                    text="JPEG, PNG, PDF"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                {isCustomObject && (
                  <TableCell sx={dataAssociationColumnStyle}>
                    <TableHeaderTypography text="Data Association" />
                  </TableCell>
                )}
                <TableCell sx={dataStatusColumnStyle}>
                  <TableHeaderTypography text="Change Status" />
                </TableCell>
                <TableCell sx={invalidReasonsColumnStyle}>
                  <TableHeaderTypography text="Reason Invalidated" />
                </TableCell>
                <TableCell sx={noteColumnStyle} />
              </TableRow>
            </TableHead>
            <TableBody>
              {documents?.map((document) => (
                <TableRow
                  key={document.properties.hubspotObjectId}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CSCDocumentTableCells
                    documentObject={document}
                    dealId={dealId}
                    isCustomObject={isCustomObject}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderS3FilesList = () => {
    const {
      documentNameColumnStyle,
      dataAssociationColumnStyle,
      uploadDateColumnStyle,
    } = getDocumentTableStyles();

    return (
      <>
        <Typography
          fontSize="25px"
          fontWeight="800"
          marginTop={4}
          marginBottom={2}
        >
          All Files
          <UploadButton
            mutationMethod={uploadFileMutation}
            allowMultipleUploads
          />
          <UploadButton
            mutationMethod={uploadDASheetMutation}
            isDASheetUpload
          />
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={documentNameColumnStyle}>
                  <TableHeaderTypography text="File Name" />
                </TableCell>
                <TableCell sx={uploadDateColumnStyle}>
                  <TableHeaderTypography text="Uploaded" />
                </TableCell>
                <TableCell sx={dataAssociationColumnStyle}>
                  <TableHeaderTypography text="Data Association" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files?.map((file) => (
                <TableRow
                  key={file.name}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CSCFileTableCells file={file} dealId={dealId} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <>
      <Header />
      {renderSubHeader()}
      {isLoadingDocumentObjects ? (
        <LoadingIndicator />
      ) : (
        <>
          {years.map((year) =>
            renderFilesList(
              year,
              documentObjects
                ?.filter((document) => document.properties.year === year)
                .sort(sortByStatus),
            ),
          )}
          {renderFilesList(
            'Other',
            documentObjects
              ?.filter((document) => !years.includes(document.properties.year))
              .sort(sortByStatus),
          )}
          {!!files?.length && renderS3FilesList()}
        </>
      )}
      <Footer />
    </>
  );
};
