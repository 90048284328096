/* eslint-disable react-hooks/rules-of-hooks */
import {
  errorHandler,
  generateNotesQueryKey,
  invalidateQueryIfNotMutating,
  successHandler,
  generateDealObjectQueryKey,
} from 'helpers';
import { useQueryClient, useMutation } from 'react-query';
import {
  DealNoteObject,
  DealNoteProperties,
  DealObject,
  DealObjectProperties,
} from 'types';

export const getDealObjectMutation = (
  updateDealObjectHandler: (
    dealProperties: Partial<DealObjectProperties>,
  ) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateDealObjectQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    Partial<DealObjectProperties>,
    { previousDealObject: DealObject | undefined }
  >(updateDealObjectHandler, {
    mutationKey,
    // TODO: revise this onMutate block
    onMutate: (newProperties) => {
      const previousDealObject: DealObject | undefined = qc.getQueryData(
        generateDealObjectQueryKey(dealId),
      );
      if (previousDealObject)
        qc.setQueryData(
          generateDealObjectQueryKey(dealId),
          (oldDealObject: DealObject | undefined) => {
            if (oldDealObject) {
              const dealObjectToOverwrite = oldDealObject;

              dealObjectToOverwrite.properties = {
                ...dealObjectToOverwrite.properties,
                ...newProperties,
              };
            }
            return oldDealObject!; // oldDealObject and previousDealObject should be the same. If previousDealObject exists, then oldDealObject should exist too
          },
        );
      return { previousDealObject };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDealObjectQueryKey(dealId),
        context?.previousDealObject,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const getCreateDealNoteObjectMutation = (
  createDealNoteObjectHandler: (
    dealNoteProperties: DealNoteProperties,
  ) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateNotesQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    DealNoteProperties,
    { previousDealNoteObjects: DealNoteObject[] | undefined }
  >(createDealNoteObjectHandler, {
    mutationKey,
    onMutate: (dealNoteProperties) => {
      const previousDealNoteObjects = qc.getQueryData<DealNoteObject[]>(
        generateNotesQueryKey(dealId),
      );

      const newDealNoteObject: DealNoteObject = {
        id: 'optimisticId',
        createdAt: 'optimisticCreatedAt',
        updatedAt: 'optimisticUpdatedAt',
        archived: false,
        properties: dealNoteProperties,
      };

      qc.setQueryData<DealNoteObject[]>(mutationKey, (oldDealNoteObjects) => [
        ...(oldDealNoteObjects ?? []),
        newDealNoteObject,
      ]);

      return { previousDealNoteObjects };
    },
    onSuccess: () => {
      // eslint-disable-next-line no-console
      successHandler('Note successfully created.');
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateNotesQueryKey(dealId),
        context?.previousDealNoteObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};
