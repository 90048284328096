import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import * as Yup from 'yup';

interface IProps {
  onMFACodeSubmit: (code: string) => Promise<void>;
  authQRCode?: string;
  goBack?: () => void;
}
export const MFACode: FunctionComponent<IProps> = ({
  onMFACodeSubmit,
  authQRCode,
  goBack,
}) => {
  const formSchema = Yup.object().shape({
    code: Yup.string().test(
      'test-number-regex-only-if-not-empty',
      'Reset code must be 6 digits',
      (code) =>
        !code || (/^[0-9]+$/.test(code) && code.toString().length === 6),
    ),
  });
  const formOptions = {
    resolver: yupResolver(formSchema),
    defaultValues: {
      code: '',
    },
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data: Record<string, string>) => {
    await onMFACodeSubmit(data.code);
  };

  return (
    <>
      {authQRCode ? (
        <>
          <Typography sx={{ margin: 1, textAlign: 'center' }} variant="h5">
            Scan QR Code with authenticator app
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
            <QRCode value={authQRCode} />
          </Box>
        </>
      ) : (
        <Typography sx={{ margin: 1, textAlign: 'center' }} variant="h5">
          Enter 6-digit code from authenticator app
        </Typography>
      )}

      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              autoFocus
              {...field}
              error={!!errors.code}
              helperText={errors.code?.message}
              sx={{ margin: 1 }}
              label="Code"
              inputProps={{
                maxLength: 6,
              }}
            />
          )}
        />

        <Box sx={{ margin: 1, display: 'flex' }}>
          {authQRCode && goBack && (
            <Button
              sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="outlined"
              onClick={goBack}
            >
              Skip MFA
            </Button>
          )}
          <Button
            sx={{ marginLeft: 0.5, flexGrow: 1, flexBasis: 0 }}
            variant="outlined"
            type="submit"
          >
            Continue
          </Button>
        </Box>
      </form>
    </>
  );
};
