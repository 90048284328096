import {
  getDealProperties,
  getDealsSummary,
  getFilesForDeal,
  getBqfDisplayNames,
  getDealNotes,
  getDealTasks,
} from 'api';
import { getFilteredDealsForSolutions } from 'api/lambda';
import {
  CACHE_TIME,
  STALE_TIME,
  errorHandler,
  generateDealObjectQueryKey,
  generate2848FilesQueryKey,
  generateDealsQueryKey,
  generateBqfDisplayNamesQueryKey,
  generateNotesQueryKey,
  generateTasksQueryKey,
  generateSolutionsDealQueryKey,
  generateTaxEngagementFilesQueryKey,
} from 'helpers';

export const dealsQuery = (prefix: string) => ({
  queryKey: generateDealsQueryKey(prefix),
  queryFn: () => getDealsSummary(prefix),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const dealPropertiesQuery = (dealId: string) => ({
  queryKey: generateDealObjectQueryKey(dealId),
  queryFn: () => getDealProperties(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const form2848FilesQuery = (dealId: string) => ({
  queryKey: generate2848FilesQueryKey(dealId),
  queryFn: () => getFilesForDeal(dealId, '2848'),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const taxEngagementFilesQuery = (dealId: string) => ({
  queryKey: generateTaxEngagementFilesQueryKey(dealId),
  queryFn: () => getFilesForDeal(dealId, 'taxEngagement'),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const bqfDisplayNamesQuery = () => ({
  queryKey: generateBqfDisplayNamesQueryKey(),
  queryFn: () => getBqfDisplayNames(),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const notesQuery = (dealId: string) => ({
  queryKey: generateNotesQueryKey(dealId),
  queryFn: () => getDealNotes(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const tasksQuery = (dealId: string) => ({
  queryKey: generateTasksQueryKey(dealId),
  queryFn: () => getDealTasks(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const solutionsDealsQuery = (pagination: number) => ({
  queryKey: generateSolutionsDealQueryKey(pagination),
  queryFn: () => getFilteredDealsForSolutions(pagination),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
