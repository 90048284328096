import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { createDealTaskObject } from 'api';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { dealPropertiesQuery, useUserEmail } from 'dataLayer';
import { errorHandler, generateTasksQueryKey, successHandler } from 'helpers';
import { FunctionComponent, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const DOCUMENT_TYPE_OPTIONS = [
  '',
  'Payroll',
  'Profit & Loss',
  'Healthcare',
  '941',
  'PPP',
];

interface IProps {
  dealId: string;
}

export const FileRequestModal: FunctionComponent<IProps> = ({ dealId }) => {
  const userEmail = useUserEmail();
  const qc = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [notes, setNotes] = useState('');
  const [cpaEmail, setCPAEmail] = useState(userEmail);

  const { data: dealProperties } = useQuery(dealPropertiesQuery(dealId));

  const handleClose = () => {
    setOpenModal(false);
  };

  const [submitState, submitAction] = useAsync(async () => {
    if (!documentType || !notes || !cpaEmail) {
      errorHandler(new Error('Do not leave any fields empty.'));
    } else if (!dealProperties?.properties.assignedCSCId) {
      errorHandler(new Error('Deal does not have an assigned CSC.'));
    } else {
      const taskProperties = {
        taskType: 'fileRequest',
        documentType,
        notes,
        taskOwnerIds: [dealProperties.properties.assignedCSCId],
        cpaEmail,
      };
      await createDealTaskObject(dealId, taskProperties);
      qc.invalidateQueries(generateTasksQueryKey(dealId));
      successHandler('Task successfully created in Hubspot');
      setOpenModal(false);
    }
  });

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setDocumentType('');
          setNotes('');
          setOpenModal(true);
        }}
        sx={{
          marginRight: 2,
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '18px',
        }}
      >
        Request File
      </Button>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography id="modal-modal-title" fontSize="25px">
            Request Additional Files
          </Typography>
          <Select
            sx={{
              marginTop: 2,
              width: 250,
            }}
            value={documentType}
            displayEmpty
            onChange={(event) => {
              setDocumentType(event.target.value);
            }}
          >
            {DOCUMENT_TYPE_OPTIONS.map((type) =>
              type === '' ? (
                <MenuItem key="none" value="">
                  Select Document Type
                </MenuItem>
              ) : (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ),
            )}
          </Select>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Notes"
            multiline
            rows={4}
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
            }}
          />
          <TextField
            sx={{ marginY: 2 }}
            fullWidth
            label="CPA Email"
            multiline
            defaultValue={cpaEmail}
            onChange={(event) => {
              setCPAEmail(event.target.value);
            }}
          />
          <Box sx={{ marginTop: 1, display: 'flex' }}>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0 }}
              disabled={submitState.status === 'loading'}
              variant="outlined"
              onClick={submitAction.execute}
            >
              {submitState.status === 'loading' ? (
                <LoadingIndicator size={20} />
              ) : (
                'Submit'
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
