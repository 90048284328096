import { AxiosError } from 'axios';
import { toast, Zoom } from 'react-toastify';

export const allowedContentTypes = (contentType: string) => {
  switch (contentType) {
    case 'application/pdf':
    case 'image/jpeg':
    case 'image/png':
      return true;
    default:
      return false;
  }
};

export const dateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = `0${(date.getMonth() + 1).toString()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export const errorHandler = (error: unknown) => {
  console.error(error);

  const errorMessage =
    ((error as AxiosError).response?.data as string) ??
    (error as Error).message ??
    (error as Error).toString();

  toast.error(errorMessage, {
    toastId: errorMessage,
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const successHandler = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const infoHandler = (message: string, toastId?: string) => {
  toast.info(message, {
    toastId,
    position: 'top-center',
    autoClose: false,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
