import { getDocumentProperties, getInvalidReasons } from 'api';
import {
  generateDocumentObjectsQueryKey,
  generateInvalidReasonsQueryKey,
  CACHE_TIME,
  STALE_TIME,
  errorHandler,
} from 'helpers';

export const documentObjectsQuery = (dealId: string) => ({
  queryKey: generateDocumentObjectsQueryKey(dealId),
  queryFn: () => getDocumentProperties(dealId),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});

export const invalidReasonsQuery = () => ({
  queryKey: generateInvalidReasonsQueryKey(),
  queryFn: () => getInvalidReasons(),
  cacheTime: CACHE_TIME,
  staleTime: STALE_TIME,
  onError: (error: unknown) => errorHandler(error),
});
