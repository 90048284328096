import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Box,
  Pagination,
  Stack,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { get941xZippedFiles } from 'api/lambda';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { solutionsDealsQuery } from 'dataLayer/Deal/get';
import { errorHandler } from 'helpers';
import { FunctionComponent, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export const SolutionsBulkDownload: FunctionComponent = () => {
  const qc = useQueryClient();
  const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
  const [pagination, setPagination] = useState(1);

  const { data: filteredDeals, isLoading: isLoadingFilteredDeals } = useQuery(
    solutionsDealsQuery(pagination),
  );

  const [downloadState, downloadAction] = useAsync(async () => {
    try {
      const file = await get941xZippedFiles(selectedDeals);
      if (file) window.open(file.url, '_blank');
      setPagination(1);
      setSelectedDeals([]);
      qc.removeQueries(['data', 'solutions', 'deals']);
      window.scrollTo(0, 0);
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <>
      <Header />
      {isLoadingFilteredDeals ? (
        <LoadingIndicator />
      ) : (
        <Box>
          <Button
            disabled={
              selectedDeals.length === 0 || downloadState.status === 'loading'
            }
            variant="outlined"
            sx={{
              marginY: 2,
              color: 'portalPalettes.green',
              borderColor: 'portalPalettes.green',
            }}
            onClick={downloadAction.execute}
          >
            {downloadState.status === 'loading' ? (
              <CircularProgress size={20} />
            ) : (
              <Typography fontSize="18px">
                {selectedDeals.length === 0
                  ? `Download 941x Files`
                  : `Download (${selectedDeals.length}) 941x Files`}
              </Typography>
            )}
          </Button>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Deal ID</TableCell>
                  <TableCell>Company Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDeals?.deals.map((deal) => (
                  <TableRow
                    key={deal.properties.hubspotObjectId}
                    sx={{
                      boxShadow: '0px 1px 0px lightgray',
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedDeals.includes(
                          deal.properties.hubspotObjectId,
                        )}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedDeals([
                              ...selectedDeals,
                              deal.properties.hubspotObjectId,
                            ]);
                          } else {
                            setSelectedDeals(
                              selectedDeals.filter(
                                (dealId) =>
                                  dealId !== deal.properties.hubspotObjectId,
                              ),
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{deal.properties.hubspotObjectId}</TableCell>
                    <TableCell>{deal.properties.companyName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil((filteredDeals?.total ?? 1) / 100)}
                variant="outlined"
                shape="rounded"
                size="large"
                page={pagination}
                onChange={(event, page: number) => setPagination(page)}
              />
            </Stack>
          </Box>
        </Box>
      )}
      <Footer />
    </>
  );
};
