import { Box, TableCell, Tooltip, Typography } from '@mui/material';
import { updateDocumentProperties } from 'api';
import { DownloadButton } from 'components/Buttons';
import { FileAssociationsDropdownMenu } from 'components/DropdownMenus/FileAssociationsDropdownMenu';
import { documentObjectsQuery, getDocumentObjectMutation } from 'dataLayer';
import { format, parseISO } from 'date-fns';
import { getDocumentTableStyles } from 'pages/Deals/Deal';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { DocumentObject } from 'types';
import { DocumentObjectProperties, S3File } from 'types/dealTypes';
import { filterAssociatedDocumentObjects } from './CSCDocumentTableCells';

interface IProps {
  file: S3File;
  dealId: string;
}

export const CSCFileTableCells: FunctionComponent<IProps> = ({
  file,
  dealId,
}) => {
  const {
    dataAssociationColumnStyle,
    documentNameColumnStyle,
    uploadDateColumnStyle,
  } = getDocumentTableStyles('fileStyle');

  const { data: documentObjects, isLoading: isLoadingDocumentObjects } =
    useQuery(documentObjectsQuery(dealId));

  const customObjectAssocations = useMemo(
    () => filterAssociatedDocumentObjects(documentObjects, file.name),
    [documentObjects, file.name],
  );

  const [selectedDocuments, setSelectedDocuments] = useState<DocumentObject[]>(
    customObjectAssocations ?? [],
  );

  const updateDocumentObjectHandler = async (documentInfo: {
    documentIds: string[];
    documentProperties: Partial<DocumentObjectProperties>;
  }) => {
    await updateDocumentProperties(
      documentInfo.documentIds,
      documentInfo.documentProperties,
    );
  };

  const updateDocumentObjectMutation = getDocumentObjectMutation(
    updateDocumentObjectHandler,
    dealId,
  );

  const lastModifiedDate = file.lastModified
    ? format(parseISO(file.lastModified), 'P')
    : '';

  useEffect(() => {
    if (customObjectAssocations) setSelectedDocuments(customObjectAssocations);
  }, [customObjectAssocations]);

  return (
    <>
      {/* TODO ADD COMMENT - Just a description of each table cell, like in the comment below */}
      {/* Download Button */}
      <TableCell sx={documentNameColumnStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: file.name,
              fileType: 'documents',
            }}
          />
        </Box>
      </TableCell>

      {/* TODO ADD COMMENT  */}
      <TableCell sx={uploadDateColumnStyle}>
        <Tooltip title={lastModifiedDate} enterDelay={0}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {lastModifiedDate}
          </Typography>
        </Tooltip>
      </TableCell>

      {/* TODO ADD COMMENT  */}
      {!isLoadingDocumentObjects && (
        <TableCell sx={dataAssociationColumnStyle}>
          <FileAssociationsDropdownMenu
            dealId={dealId}
            file={file}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            updateDocumentObjectMutation={updateDocumentObjectMutation}
          />
        </TableCell>
      )}
    </>
  );
};
