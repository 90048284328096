import { Button, CircularProgress, Icon, Typography } from '@mui/material';
import { UploadIcon } from 'assets/icons';
import React, { FunctionComponent, useRef } from 'react';
import { UseMutationResult } from 'react-query/types/react/types';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutationMethod: UseMutationResult<void, unknown, any, unknown>;
  allowMultipleUploads?: boolean;
  isDASheetUpload?: boolean;
}

export const UploadButton: FunctionComponent<IProps> = ({
  mutationMethod,
  allowMultipleUploads,
  isDASheetUpload,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        onChange={mutationMethod.mutate}
        onClick={(event) => {
          // eslint-disable-next-line no-param-reassign
          event.currentTarget.value = '';
        }}
        type="file"
        multiple={allowMultipleUploads}
        accept={
          isDASheetUpload
            ? [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel.sheet.macroEnabled.12',
              ].join(',')
            : [
                'image/png',
                'image/jpeg',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/csv',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel.sheet.macroEnabled.12',
              ].join(',')
        }
        hidden
      />
      <Button
        disabled={mutationMethod.isLoading}
        disableRipple
        variant="text"
        onClick={() => inputRef.current?.click()}
        sx={{
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          maxWidth: '30%',
        }}
      >
        {mutationMethod.isLoading ? (
          <CircularProgress size={20} sx={{ paddingX: 1 }} />
        ) : (
          <Icon>
            <img style={{ verticalAlign: 'top' }} src={UploadIcon} />
          </Icon>
        )}
        <Typography fontSize="16px" color="portalPalettes.green">
          {isDASheetUpload ? 'Upload DA Sheet' : 'Upload'}
        </Typography>
      </Button>
    </>
  );
};
