import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { createDealTaskObject } from 'api';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { dealPropertiesQuery } from 'dataLayer';
import {
  errorHandler,
  generateDealObjectQueryKey,
  successHandler,
} from 'helpers';
import { FunctionComponent, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

interface IProps {
  dealId: string;
}

export const RejectCaseModal: FunctionComponent<IProps> = ({ dealId }) => {
  const qc = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState('');

  const { data: dealProperties } = useQuery(dealPropertiesQuery(dealId));

  const handleClose = () => {
    setOpenModal(false);
  };

  const [submitState, submitAction] = useAsync(async () => {
    if (!notes) {
      errorHandler(new Error('Please enter a rejection reason.'));
    } else if (
      !dealProperties?.properties.assignedCSCId ||
      !dealProperties?.properties.dealOwnerId
    ) {
      errorHandler(
        new Error('Deal does not have an assigned CSC or deal owner.'),
      );
    } else {
      const taskProperties = {
        taskType: 'caseRejection',
        notes,
        taskOwnerIds: [
          dealProperties.properties.assignedCSCId,
          dealProperties.properties.dealOwnerId,
        ],
      };
      await createDealTaskObject(dealId, taskProperties);
      qc.invalidateQueries(generateDealObjectQueryKey(dealId));
      successHandler('Case Rejected');
      handleClose();
    }
  });

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        sx={{
          marginRight: 2,
          fontSize: '18px',
        }}
        onClick={() => {
          setNotes('');
          setOpenModal(true);
        }}
      >
        Reject Case
      </Button>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography id="modal-modal-title" fontSize="25px">
            Please explain reason for rejection
          </Typography>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label="Reason"
            multiline
            rows={4}
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
            }}
          />
          <Box sx={{ marginTop: 1, display: 'flex' }}>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0, marginRight: 0.5 }}
              disabled={submitState.status === 'loading'}
              variant="contained"
              color="error"
              onClick={submitAction.execute}
            >
              {submitState.status === 'loading' ? (
                <LoadingIndicator size={20} />
              ) : (
                'Reject Case'
              )}
            </Button>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0, marginLeft: 0.5 }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
