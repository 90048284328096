import { Divider, Typography } from '@mui/material';
import { Footer } from 'components/Footer';
import { DealHeader, Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { bqfDisplayNamesQuery, dealPropertiesQuery } from 'dataLayer';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { DealObjectProperties } from 'types';

export const BusinessQualifyForm: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const { data: dealProperties } = useQuery(dealPropertiesQuery(dealId));

  const { data: bqfDisplayNames, isLoading: isLoadingBqfDisplayNames } =
    useQuery(bqfDisplayNamesQuery());

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      {isLoadingBqfDisplayNames ? (
        <LoadingIndicator />
      ) : (
        <>
          {Object.entries(bqfDisplayNames ?? []).map(([key, value]) => {
            if (
              key === 'assignedCSCId' ||
              key === 'dealOwnerId' ||
              key === 'taxAttorneyRejectedDate'
            )
              return undefined;
            return (
              <>
                <Typography
                  fontSize="18px"
                  fontWeight="500"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {value}
                </Typography>
                <Typography sx={{ paddingLeft: 2, paddingY: 1.5 }}>
                  {
                    dealProperties?.properties[
                      key as keyof DealObjectProperties
                    ]
                  }
                </Typography>
                <Divider
                  sx={{
                    borderWidth: 1,
                    borderColor: 'darkgray',
                    marginBottom: 4,
                  }}
                />
              </>
            );
          })}
        </>
      )}
      <Footer />
    </>
  );
};
