import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useAsync, useDebouncedCallback } from '@react-hookz/web';
import { updateDocumentProperties } from 'api';
import { copyFile } from 'api/lambda';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { getDocumentObjectMutation } from 'dataLayer';
import { errorHandler, successHandler } from 'helpers';
import { generateFilesQueryKey } from 'helpers/queryKeys';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { DocumentObject } from 'types';
import { DocumentObjectProperties } from 'types/dealTypes';

interface IProps {
  documentObject: DocumentObject;
  dealId: string;
}

export const EditDocumentModal: FunctionComponent<IProps> = ({
  documentObject,
  dealId,
}) => {
  const qc = useQueryClient();
  const [openModal, setOpenModal] = useState(false);

  const updateDocumentObjectHandler = async (documentInfo: {
    documentIds: string[];
    documentProperties: Partial<DocumentObjectProperties>;
  }) => {
    await updateDocumentProperties(
      documentInfo.documentIds,
      documentInfo.documentProperties,
    );
  };

  const updateDocumentObjectMutation = getDocumentObjectMutation(
    updateDocumentObjectHandler,
    dealId,
  );

  const handleClose = (event: SyntheticEvent, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  const updateDocumentInternalNotes = useDebouncedCallback(
    (internalNotes) => {
      updateDocumentObjectMutation.mutate({
        documentIds: [documentObject.id],
        documentProperties: {
          internalNotes,
        },
      });
    },
    [],
    1000,
    0,
  );

  const updateDocumentFileNamingConvention = useDebouncedCallback(
    (fileNamingConvention) => {
      updateDocumentObjectMutation.mutate({
        documentIds: [documentObject.id],
        documentProperties: {
          fileNamingConvention,
        },
      });
    },
    [],
    1000,
    0,
  );

  const [copyFileToFileRequestsState, copyFileToFileRequestsAction] = useAsync(
    async () => {
      try {
        await copyFile(
          dealId,
          documentObject.properties.s3FileName!,
          'documents',
          'fileRequests',
        );
        qc.invalidateQueries(generateFilesQueryKey(dealId, 'fileRequests'));
        successHandler('File successfully copied to file requests.');
      } catch (err) {
        errorHandler(err);
      }
    },
  );

  return (
    <>
      <IconButton id="modal-open-button" onClick={() => setOpenModal(true)}>
        <Badge
          color="error"
          variant="dot"
          invisible={!documentObject.properties.internalNotes}
        >
          <MoreVertIcon />
        </Badge>
      </IconButton>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography id="modal-modal-title" fontSize="25px">
            {documentObject.properties.displayName}
          </Typography>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            id="outlined-multiline-static"
            label="File Display Name"
            defaultValue={documentObject.properties.fileNamingConvention}
            onChange={(event) =>
              updateDocumentFileNamingConvention(event.target.value)
            }
          />
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            id="outlined-multiline-static"
            label="Notes"
            multiline
            rows={4}
            defaultValue={documentObject.properties.internalNotes}
            onChange={(event) =>
              updateDocumentInternalNotes(event.target.value)
            }
          />
          <Box sx={{ marginY: 1, display: 'flex' }}>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0 }}
              variant="contained"
              disabled={
                !documentObject.properties.s3FileName ||
                copyFileToFileRequestsState.status === 'loading'
              }
              onClick={copyFileToFileRequestsAction.execute}
            >
              {copyFileToFileRequestsState.status === 'loading' ? (
                <LoadingIndicator size={20} />
              ) : (
                'Copy file to File Requests'
              )}
            </Button>
          </Box>
          <Box sx={{ marginTop: 1, display: 'flex' }}>
            <Button
              sx={{ flexGrow: 1, flexBasis: 0 }}
              variant="outlined"
              onClick={() => setOpenModal(false)}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
