import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  ListItemButton,
  ListItemText,
  List,
  ListItem,
  CircularProgress,
} from '@mui/material';
import {
  archiveFile,
  createDealNoteObject,
  upload941xFile,
  uploadFile,
} from 'api';
import { Footer } from 'components/Footer';
import { DealHeader, Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { CPACreditTableCells } from 'components/TableCells';

import {
  TableBoldedTypography,
  TableCommonTypography,
  TableHeaderTypography,
} from 'components/Typography';
import {
  creditObjectsQuery,
  getCreateDealNoteObjectMutation,
  notesQuery,
  getS3FileUploadMutation,
  filesQuery,
  form2848FilesQuery,
  getMutationFor2848Files,
  taxEngagementFilesQuery,
  getMutationForTaxEngagementFiles,
} from 'dataLayer';
import { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import format from 'date-fns/format';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { DealNoteObject, DealNoteProperties } from 'types';
import { DownloadButton, UploadButton } from 'components/Buttons';
import { dateToYYYYMMDD, successHandler } from 'helpers';
import { updateDealProperties } from 'api/lambda';
import {
  checkboxColumnStyle,
  creditObjectColumnStyle,
  creditObjectNameColumnStyle,
} from './formTableStyles';

const getNoteWithFormattedTimestamp = (note: DealNoteObject) =>
  `${format(new Date(note.properties.timestamp), 'MM/dd/yyyy')} -
  ${note.properties.note}`;

export const DealCPA: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const [noteValue, setNoteValue] = useState('');

  const { data: filesFor2848Form, isLoading: isLoadingFilesFor2848Form } =
    useQuery(form2848FilesQuery(dealId));

  const {
    data: filesForTaxEngagement,
    isLoading: isLoadingFilesForTaxEngagement,
  } = useQuery(taxEngagementFilesQuery(dealId));

  const { data: files } = useQuery(filesQuery(dealId, 'taxDocuments'));

  const { data: creditObjects, isLoading: isLoadingCreditObjects } = useQuery(
    creditObjectsQuery(dealId),
  );

  const { data: noteObjects, isLoading: isLoadingNotes } = useQuery(
    notesQuery(dealId),
  );

  const getAmended941FileName = useMemo(() => {
    const filesWith941FileName =
      files?.filter((file) => file.name.includes(`Amended941x - ${dealId}`)) ??
      [];
    if (filesWith941FileName.length) {
      filesWith941FileName.sort((file1, file2) => {
        const modifiedDateFile1 = new Date(file1.lastModified);
        const modifiedDateFile2 = new Date(file2.lastModified);

        return modifiedDateFile1.valueOf() - modifiedDateFile2.valueOf();
      });
      return filesWith941FileName.pop();
    }
    return undefined;
  }, [files, dealId]);

  const upload2848FileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesFor2848Form) {
      await Promise.all(
        filesFor2848Form.map(async (formFile) => {
          await archiveFile(dealId, formFile.name, '2848');
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(dealId, '2848', file);
      successHandler('File successfully uploaded!');
    }
  };

  const uploadTaxEngagementFileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesForTaxEngagement) {
      await Promise.all(
        filesForTaxEngagement.map(async (taxEngagementFile) => {
          await archiveFile(dealId, taxEngagementFile.name, 'taxEngagement');
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(dealId, 'taxEngagement', file);
      successHandler('File successfully uploaded!');
    }
  };

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await upload941xFile(dealId, 'taxDocuments', file);
      await updateDealProperties([dealId], {
        n941xUploadDate: dateToYYYYMMDD(new Date()),
      });
      successHandler('File successfully uploaded!');
    }
  };

  const upload2848FormMutation = getMutationFor2848Files(
    upload2848FileHandler,
    dealId,
  );

  const uploadTaxEngagementMutation = getMutationForTaxEngagementFiles(
    uploadTaxEngagementFileHandler,
    dealId,
  );

  const uploadFileMutation = getS3FileUploadMutation(
    uploadFileHandler,
    dealId,
    'taxDocuments',
  );

  const createDealNoteObjectHandler = async (
    dealNoteProperties: DealNoteProperties,
  ) => {
    await createDealNoteObject(dealId, dealNoteProperties);
  };

  const createDealNoteMutation = getCreateDealNoteObjectMutation(
    createDealNoteObjectHandler,
    dealId,
  );

  const createNote = () => {
    createDealNoteMutation.mutate({
      timestamp: new Date().toISOString(),
      note: noteValue,
      dealId,
    });
    setNoteValue('');
  };

  const creditObjectTypes = [
    { displayName: '941x - 2020 Q2', hubspotName: '2020 ERC | Q2' },
    { displayName: '941x - 2020 Q3', hubspotName: '2020 ERC | Q3' },
    { displayName: '941x - 2020 Q4', hubspotName: '2020 ERC | Q4' },
    { displayName: '941x - 2021 Q1', hubspotName: '2021 ERC | Q1' },
    { displayName: '941x - 2021 Q2', hubspotName: '2021 ERC | Q2' },
    { displayName: '941x - 2021 Q3', hubspotName: '2021 ERC | Q3' },
    { displayName: '941x - 2021 Q4', hubspotName: '2021 ERC | Q4' },
    { displayName: '943x - 2020', hubspotName: '2020 ERC | 943' },
    { displayName: '943x - 2021', hubspotName: '2021 ERC | 943' },
  ];

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Typography
          fontSize="20px"
          color="portalPalettes.darkgray"
          fontWeight="700"
        >
          2848 Form/Tax Engagement Letter
        </Typography>
        <Typography fontSize="16px" color="#777777">
          All uploads and edits are saved automatically
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              height: '75px',
            }}
          >
            <TableRow>
              <TableCell>
                <TableHeaderTypography text="Form Name" />
                <TableCommonTypography
                  text="Upload Document Name"
                  sx={{ paddingTop: 1 }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {isLoadingFilesFor2848Form ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="2848" />
                      <UploadButton mutationMethod={upload2848FormMutation} />
                    </Box>
                    {!!filesFor2848Form?.[0]?.name && (
                      <DownloadButton
                        dealId={dealId}
                        fileInformation={{
                          fileName: filesFor2848Form[0].name,
                          fileType: '2848',
                        }}
                        isUploading={upload2848FormMutation.isLoading}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isLoadingFilesForTaxEngagement ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TableBoldedTypography text="Tax Engagement Letter" />
                      <UploadButton
                        mutationMethod={uploadTaxEngagementMutation}
                      />
                    </Box>
                    {!!filesForTaxEngagement?.[0]?.name && (
                      <DownloadButton
                        dealId={dealId}
                        fileInformation={{
                          fileName: filesForTaxEngagement[0].name,
                          fileType: 'taxEngagement',
                        }}
                        isUploading={uploadTaxEngagementMutation.isLoading}
                      />
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        fontSize="20px"
        color="portalPalettes.darkgray"
        fontWeight="700"
        marginTop={4}
        marginBottom={2}
      >
        941x / 943x Forms
        <UploadButton mutationMethod={uploadFileMutation} />
        {getAmended941FileName && (
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: getAmended941FileName.name,
              fileType: 'taxDocuments',
            }}
            isUploading={uploadFileMutation.isLoading}
          />
        )}
      </Typography>
      {isLoadingCreditObjects ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow>
                <TableCell sx={checkboxColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Qualifies" />
                  <TableHeaderTypography text="for credit" />
                </TableCell>
                <TableCell sx={creditObjectNameColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Form Name" />
                  <TableCommonTypography
                    text="Upload Document Name"
                    sx={{ paddingTop: 1 }}
                  />
                </TableCell>
                <TableCell sx={creditObjectColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Confirm Qualifying Credits" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creditObjectTypes.map((creditObjectType) => (
                <TableRow
                  key={creditObjectType.hubspotName}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CPACreditTableCells
                    dealId={dealId}
                    creditObjectType={creditObjectType}
                  />
                </TableRow>
              ))}
              <TableRow key="refund-total" sx={{ backgroundColor: '#F9F9F9' }}>
                <TableCell />

                {/* TODO ADD COMMENT - Just a description of each table cell, like in the comment below */}
                {/* Row Title */}
                <TableCell>
                  <Typography
                    fontSize="20px"
                    color="portalPalettes.darkgray"
                    fontWeight="700"
                  >
                    Total qualifying credits
                  </Typography>
                </TableCell>

                {/* TODO ADD COMMENT  */}
                <TableCell>
                  <NumberFormat
                    thousandSeparator
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{
                      fontSize: '20px',
                      color: 'portalPalettes.darkgray',
                      fontWeight: '700',
                    }}
                    displayType="text"
                    value={
                      creditObjects?.reduce(
                        (previousValue: number, creditObject) =>
                          previousValue +
                          parseFloat(
                            `${
                              creditObject.properties.estimatedRefundAmount ?? 0
                            }`,
                          ),
                        0,
                      ) ?? 0
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Notes Section */}
      <Typography
        fontSize="20px"
        color="portalPalettes.darkgray"
        fontWeight="700"
        marginTop={4}
        marginBottom={2}
      >
        Notes
      </Typography>

      {isLoadingNotes && <CircularProgress size={20} sx={{ paddingX: 1 }} />}

      {!isLoadingNotes && noteObjects && (
        <Box
          sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: 2 }}
        >
          <List>
            {noteObjects.map((note) => (
              <ListItem key={note.id} disablePadding>
                <ListItemButton>
                  <ListItemText primary={getNoteWithFormattedTimestamp(note)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <TextField
        fullWidth
        multiline
        id="note"
        label="Note"
        placeholder="Add note..."
        value={noteValue}
        onChange={(event) => setNoteValue(event.currentTarget.value)}
        rows={3}
      />
      <Button
        // disabled={isLoading}
        disableRipple
        variant="outlined"
        onClick={createNote}
        sx={{
          marginTop: 2,
          color: 'portalPalettes.green',
          borderColor: 'portalPalettes.green',
          fontSize: '18px',
        }}
      >
        <Typography fontSize="16px" color="portalPalettes.green">
          Save Note
        </Typography>
      </Button>
      <Footer />
    </>
  );
};
