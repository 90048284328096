/* eslint-disable react-hooks/rules-of-hooks */
import {
  errorHandler,
  generate2848FilesQueryKey,
  generateCreditObjectsQueryKey,
  generateTaxEngagementFilesQueryKey,
  invalidateQueryIfNotMutating,
} from 'helpers';
import { ChangeEvent } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { CreditObject, CreditObjectProperties, S3File } from 'types';

export const getCreditObjectMutation = (
  updateCreditObjectHandler: (
    creditProperties: Partial<CreditObjectProperties>,
  ) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateCreditObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    Partial<CreditObjectProperties>,
    { previousCreditObjects: CreditObject[] | undefined }
  >(updateCreditObjectHandler, {
    mutationKey,
    onMutate: (newProperties) => {
      const previousCreditObjects: CreditObject[] | undefined = qc.getQueryData(
        generateCreditObjectsQueryKey(dealId),
      );
      qc.setQueryData(
        generateCreditObjectsQueryKey(dealId),
        (oldCreditObjects: CreditObject[] | undefined) => {
          if (oldCreditObjects) {
            const creditObjectToOverwrite = oldCreditObjects?.find(
              (creditObj) => creditObj.properties.name === newProperties.name,
            );
            if (creditObjectToOverwrite) {
              creditObjectToOverwrite.properties = {
                ...creditObjectToOverwrite.properties,
                ...newProperties,
              };
            }
          }
          return oldCreditObjects ?? [];
        },
      );
      return { previousCreditObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateCreditObjectsQueryKey(dealId),
        context?.previousCreditObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const getCreateCreditObjectMutation = (
  createCreditObjectHandler: (
    creditProperties: Partial<CreditObjectProperties>,
  ) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateCreditObjectsQueryKey(dealId);

  return useMutation<void, unknown, Partial<CreditObjectProperties>>(
    createCreditObjectHandler,
    {
      mutationKey,
      onMutate: (creditProperties) => {
        const previousCreditObjects: CreditObject[] | undefined =
          qc.getQueryData(generateCreditObjectsQueryKey(dealId));

        const newCreditObject: CreditObject = {
          id: 'optimisticId',
          createdAt: 'optimisticCreatedAt',
          updatedAt: 'optimisticUpdatedAt',
          archived: false,
          properties: {
            name: creditProperties?.name ?? 'optimisticName',
            hubspotCreateDate: 'optimisticHubspotCreateDate',
            hubspotLastModifiedDate: 'optimisticHubspotLastModifiedDate',
            hubspotObjectId: 'optimisticObjectId',
          },
        };

        qc.setQueryData(
          generateCreditObjectsQueryKey(dealId),
          (oldCreditObjects: CreditObject[] | undefined) => [
            ...(oldCreditObjects ?? []),
            newCreditObject,
          ],
        );

        return { previousCreditObjects };
      },
      onError: (err) => {
        errorHandler(err);
      },
      onSettled: () => {
        invalidateQueryIfNotMutating(qc, mutationKey);
      },
    },
  );
};

export const getDeleteCreditObjectMutation = (
  deleteCreditObjectHandler: (creditId: string) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateCreditObjectsQueryKey(dealId);

  return useMutation<void, unknown, string>(deleteCreditObjectHandler, {
    mutationKey,
    onMutate: (creditId) => {
      const previousCreditObjects: CreditObject[] | undefined = qc.getQueryData(
        generateCreditObjectsQueryKey(dealId),
      );
      qc.setQueryData(
        generateCreditObjectsQueryKey(dealId),
        (oldCreditObjects: CreditObject[] | undefined) =>
          oldCreditObjects?.filter((creditObj) => creditObj.id !== creditId) ??
          [],
      );
      return { previousCreditObjects };
    },
    onError: (err) => {
      errorHandler(err);
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const getCreditObjectMutationForUploadProperties = (
  updateCreditObjectHandler: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>,
  dealId: string,
  currentCreditObject: CreditObject | undefined,
) => {
  const qc = useQueryClient();
  const mutationKey = generateCreditObjectsQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousCreditObjects: CreditObject[] | undefined }
  >(updateCreditObjectHandler, {
    mutationKey,
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousCreditObjects: CreditObject[] | undefined = qc.getQueryData(
        generateCreditObjectsQueryKey(dealId),
      );
      if (fileName && currentCreditObject) {
        qc.setQueryData(
          generateCreditObjectsQueryKey(dealId),
          (oldCreditObjects: CreditObject[] | undefined) => {
            if (oldCreditObjects) {
              const creditObjectToOverwrite = oldCreditObjects?.find(
                (creditObj) => creditObj.id === currentCreditObject.id,
              );
              if (creditObjectToOverwrite) {
                creditObjectToOverwrite.properties = {
                  ...creditObjectToOverwrite.properties,
                  s3FileName: fileName,
                };
              }
            }
            return oldCreditObjects ?? [];
          },
        );
      }
      return { previousCreditObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateCreditObjectsQueryKey(dealId),
        context?.previousCreditObjects,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const getMutationFor2848Files = (
  uploadFileHandler: (event: ChangeEvent<HTMLInputElement>) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generate2848FilesQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previous2848Documents: S3File[] | undefined }
  >(uploadFileHandler, {
    mutationKey,
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previous2848Documents: S3File[] | undefined = qc.getQueryData(
        generate2848FilesQueryKey(dealId),
      );
      if (fileName) {
        let newDealDocument: S3File;
        if (previous2848Documents && previous2848Documents?.length > 0) {
          newDealDocument = {
            ...previous2848Documents?.[0],
          };
          newDealDocument.name = fileName;
        } else {
          newDealDocument = {
            name: fileName,
            size: 0,
            lastModified: '',
            contentType: '',
            url: '',
          };
        }

        qc.setQueryData(generate2848FilesQueryKey(dealId), () => [
          newDealDocument,
        ]);
      }
      return { previous2848Documents };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generate2848FilesQueryKey(dealId),
        context?.previous2848Documents,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};

export const getMutationForTaxEngagementFiles = (
  uploadFileHandler: (event: ChangeEvent<HTMLInputElement>) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const mutationKey = generateTaxEngagementFilesQueryKey(dealId);

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousTaxEngagementFiles: S3File[] | undefined }
  >(uploadFileHandler, {
    mutationKey,
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousTaxEngagementFiles: S3File[] | undefined = qc.getQueryData(
        generateTaxEngagementFilesQueryKey(dealId),
      );
      if (fileName) {
        let newDealDocument: S3File;
        if (
          previousTaxEngagementFiles &&
          previousTaxEngagementFiles?.length > 0
        ) {
          newDealDocument = {
            ...previousTaxEngagementFiles?.[0],
          };
          newDealDocument.name = fileName;
        } else {
          newDealDocument = {
            name: fileName,
            size: 0,
            lastModified: '',
            contentType: '',
            url: '',
          };
        }

        qc.setQueryData(generateTaxEngagementFilesQueryKey(dealId), () => [
          newDealDocument,
        ]);
      }
      return { previousTaxEngagementFiles };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateTaxEngagementFilesQueryKey(dealId),
        context?.previousTaxEngagementFiles,
      );
    },
    onSettled: () => {
      invalidateQueryIfNotMutating(qc, mutationKey);
    },
  });
};
