/* eslint-disable react-hooks/rules-of-hooks */
import { errorHandler } from 'helpers';
import { generateFilesQueryKey } from 'helpers/queryKeys';
import { ChangeEvent } from 'react';
import { useQueryClient, useMutation } from 'react-query';

export const getS3FileUploadMutation = (
  fileUploadHandler: (event: ChangeEvent<HTMLInputElement>) => Promise<void>,
  dealId: string,
  fileType: string,
) => {
  const qc = useQueryClient();

  return useMutation<void, unknown, ChangeEvent<HTMLInputElement>>(
    fileUploadHandler,
    {
      onError: (err) => {
        errorHandler(err);
      },
      onSettled: () => {
        qc.invalidateQueries(generateFilesQueryKey(dealId, fileType));
      },
    },
  );
};
